import React, { useState, useEffect } from 'react';
import {
  Link
} from "react-router-dom";
import MobileNav from './mobileNav';
import Logo from './logo';

function Header(props){
  const [header, setHeader] = useState("header");
  const [windowDimension, setWindowDimension] = useState(null);

  const listenScrollEvent = (event) => {
    if (window.scrollY < 77) {
      return setHeader('');
    } else if (window.scrollY > 75) {
      return setHeader("scrollHeader")
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    setWindowDimension(window.innerWidth);
    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 1050;

  return (
    <header className={`c-header__wrapper ${header}`}>
      <div className="grid-container">
      {isMobile ? (
        <MobileNav
        color = {props.color}/>
      ) : (
      <nav className={`menu c-header ${props.color}`}>
        <Link to="/" className="logo-wrapper"><Logo className="logo"></Logo></Link>
        <Link to="/features">Features</Link>
        <Link to="/buildYourCrew">Crew as a gift</Link>
        <Link to="/news">News</Link>
        <Link to="/about">About</Link>
       
      </nav>
      )}
      </div>
    </header>
  );
}

export default Header;
