import React, { Component } from 'react';
import Footer from '../footer'
import {
  Grid,
  Cell
} from 'react-foundation';

import Header from '../header';
import BigStatement from '../bigStatement';
import HeaderButtons from '../headerButtons';
import FeatureCarousel from '../featureCarousel';
import TestimonialBlock from '../testimonialBlock';
import CallToAction from '../callToAction';
import {heros} from '../constants.js'

class HomePage extends Component {
  createCrewPlatform(){
    let popupLink = 'http://crew.cat/platform/welcome';
    popupLink = "/registration-success";

    const onMessageFunction = (data) => 
    {
      window.location.href = 'http://crew.cat/platform/welcome';
      // window.open('https://www.crewplatform.org/platform/welcome', '_blank');
    }
    window.removeEventListener('message', onMessageFunction);

    const popUpFeatures = 'toolbar=no, menubar=no, width=700, height=700, top=100, left=100';
    if (!window.crewPopup || window.crewPopup.closed)
    {
      window.crewPopup = window.open(popupLink, '', popUpFeatures);
    } else 
    {
      window.crewPopup.focus();
    }

    window.addEventListener('message', msgEvent => onMessageFunction(msgEvent.data), false);
  }
  render() {
    return (
      <div id="home">
        <Header color="dark"/>
        <div className="sticky grid-container">
          <Grid className="grid-margin-x">
            <Cell className="small-12 medium-6 u-flex-center">
              <h1>One platform to serve all your program management needs.</h1>
              <h3>Free. Scalable. Customizable.</h3>
           
            </Cell>
            <Cell className="small-12 medium-6">
              <img src={heros.homeHero} alt="Crew Members holding up the world" />
            </Cell>
          </Grid>
        </div>
        <BigStatement
          textColor='u-text--white'
          subtitle='Crew For All is a 501c3 nonprofit organization'
          headline='Equip, train and connect your community with the Crew Platform. Made for movement builders, by movement builders.'
          gradient='home'
        />
        <div className="grid-container">
         <div className="o-section--large">
            <FeatureCarousel />
          </div>
          <div className="o-section--large">
            <h2 className="u-bold text-center">For community-focused programs on a mission.</h2>
            <p className="u-text--mb2 u-description--center">We work with organizations that mobilize and equip communities to solve critical problems and advance positive social change.</p>
            <TestimonialBlock />
          </div>
        </div>
        <CallToAction 
          headline="The Crew Platform will be turning off in October 2025"
          description="After a successful run of almost 10 years hosting groups working to make the world a more loving, just and sustainable place, the Crew for All team has decided that the best path forward in their technology evolution is to join forces with Hylo to build technology together!"
          buttonText="Read more here!"
          link="https://www.crewforall.org/news?p=hylo-and-crew"  
          onClick={() => {this.createCrewPlatform()}}
        />
        <Footer />
      </div>
    )
  }
}

export default HomePage;


