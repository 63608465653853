import React, { Component } from 'react';
import {teamPhotos} from './constants'
import {
  Grid,
  Cell,
} from 'react-foundation';

//Images

const staff = [
  
  {
    title: 'Talis Apud-Martinez',
    url: 'https://www.linkedin.com/in/talisapud',
    id: 'talis',
    subtitle: 'Board Chair, Co-Founder.',
    image: `${teamPhotos.talis}`,
    bio: [
        "Talis' roots are deeply planted in community, grassroots movements, and entrepreneurship. It started with FeelGood: a youth movement led by students running grilled cheese delis on university campuses across the country, founded by Talis and Kristin Walter (Advisor) in their college days. As FeelGood grew and spread, the need for a tech solution to train, equip and connect students prompted them to found Crew.",
        "In her own words: “The origins of Crew were organic; we were experiencing a specific need, realized others shared it, and responded to that need. The spirit of that origin story continues as we evolve our platform as a community of nonprofits. I love being a teammate to the leaders of our partner organizations. I get to learn from them, relay the trends that are being experienced, and then integrate their feedback in a meaningful way. To be able to see the way it our platform supports the good work of users and elevates their organizion's mission, is truly inspiring.”",
        "Talis has BS in Sociology from UT Austin and an MS in Innovation from the Tec de Monterrey. Born and raised in Mexico, she now lives in Halifax, Canada, with her husband and two kids, and is deeply committed to building community everywhere she goes."
      ]  
    },
 {
    title: 'Pong Qi',
    url: 'https://www.linkedin.com/in/huapeng-qi-95520434',
    id: 'pong',
    subtitle: 'Board Member, Co-Founder.',
    image: `${teamPhotos.pong}`,
    bio: 
      [
      'Like the rest of the founding team, Pong\'s connection to Crew starts with FeelGood: he was an active member of the Berkeley chapter all through his college experience. After earning degrees in computer science and applied math, Pong helped build Crew from the ground, up. He’s served as lead developer since its inception.',
      'In his own words: “The most important part of any platform is its content, and good content is about adding value. Simply engaging users takes their time, but adding value gives them time back. On our platform, the content is generated by users. Our goal is to equip them with the best possible tools to deliver more valuable, more informative content that inspires action.”',
      'A native of Qingdao, China, Pong currently lives in Portland, Oregon where he pursues his active passions — snowboarding, canyoneering, surfing, shellfishing and climbing. His personal mission is to improve the planet for everyone, and he enjoys its bounties as passionately as he works to protect them.'
      ]  
    },
 {
    title: 'Michelle Child',
    url: 'https://www.linkedin.com/in/michellechild',
    id: 'michelle',
    subtitle: 'Board Secretary, Co-Founder.',
    image: `${teamPhotos.michelle}`,
    bio: [
        'As Crew’s lead for UX design and front-end coding, Michelle brings beauty and intuitive use to the platform. After graduating from Western Kentucky University, where she served as a FeelGood chapter leader, Michelle worked for the Americorps Vista program with partners for active living. Living on a poverty wage, Michelle helped lead work in low-income communities that focused on public health — healthy eating, active living, even trail development. As part of her work with Americorps, Michelle helped build a website that compiled trails, parks and other local public places for kids to find active outdoor time. It was here that her knack for user experience became apparent.',
      'Michelle lives in Spartanburg, South Carolina with her family. She enjoys camping, being outdoors, and a never-ending list of projects on her historic 1920s home.'
    ]
  },
  {
    title: 'Erin Rice',
    url: 'https://www.linkedin.com/in/human-erin',
    id: 'erin',
    subtitle: 'Director of Operations',
    image: `${teamPhotos.erin}`,
    bio: [
        'Erin has an expansive career in public service that has ranged from social work to the medical field to the legal system. She lives in Austin with her husband, an entertainment attorney and musician, and their three kids. Music is a big part of their world, and they take in as many shows as they can as a family. They also love to camp, hike and spend time outdoors.',
        'In her own words: “I grew up in poverty, surrounded by people who saw no way out. I know what it\'s like to feel invisible and hungry and helpless and unsafe. Those are the reasons I became a social worker, and the reasons I love all our Crew Partners - because they address poverty, hunger, inequality, education and safety. Education is the reason I made it out, and it\'s the reason I had the opportunity to try on so many different hats. All of my time spent waiting tables, or as an endoscopy technician, or with CPS, all of that makes me a better director of operations for Crew because I have seen so many people from so many different worlds and carry them with me through everything I do. It all led me all here, and despite its many chapters, the whole trajectory feels like a straight line to me.”'
      ] 
    },
 {
    title: 'Annie Liu Johnston',
    url: 'https://www.linkedin.com/in/anne-liu-johnston-32b4aa',
    id: 'annie',
    subtitle: 'Lead Developer',
    image: `${teamPhotos.annie}`,
    location: '',
    bio: [
      'Annie earned her BS in environmental engineering from Cornell University in 1997. Since then, she has spent the past 20+ years programming. Annie has led the development and design of dozens of amazing features on the Crew Platform for the last three years, making it possible to respond to the needs and vision of our community. Annie lives in Austin, Texas with her husband and three kids.  She enjoys traveling and hiking and trying to expose her kids to new experiences, whether they like it or not.'
    ]
  }
]

const advisors = [
  
  {
    title: 'Kristin Walter',
    url: 'https://www.linkedin.com/in/kristin-walter-2a18206',
    id: 'kristin',
    subtitle: 'Crew Baord Treasurer, Co-Founder.',
    image: `${teamPhotos.kristin}`,
  },
  {
    title: 'Kern Beare',
    url: 'https://www.linkedin.com/in/kern-beare-7346251',
    id: 'kern',
    subtitle: 'Crew Co-Founder. Difficult Conversations Project, Founder',
    image: `${teamPhotos.kern}`,
  },
  {
    title: 'Xin Liu',
    url: 'https://www.linkedin.com/in/xin-liu-b29b507',
    id: 'xin',
    subtitle: 'Crew\'s first funder. Enlight Foundation, Co-Founder and Director',
    image: `${teamPhotos.xin}`,
  },
  {
    title: 'Geoff Johnston',
    url: 'https://www.linkedin.com/in/geoff-johnston-a194322',
    id: 'geoff',
    subtitle: 'Senior IT Operations and automation architect',
    image: `${teamPhotos.geoff}`,
  },
  {
    title: 'Wilson Sonsini',
    url: 'https://www.wsgr.com/',
    id: 'wilson',
    subtitle: 'Pro-bono Legal Advisers',
    image: `${teamPhotos.wilson}`,
  },
   {
    title: 'Brooks Rice',
    url: 'https://www.brooksricelaw.com/',
    id: 'brooks',
    subtitle: 'Managing Attorney',
    image: `${teamPhotos.brooks}`,
  },
]

function Staff(props){
  const staff=props.staff;
  return(
    <Grid className="grid-margin-x medium-up-2 u-text--mb2">
      { 
       staff.map((staff,index) =>
          <Cell className={`c-card--center`}>
            <img src={staff.image} className="c-image--300 c-image--main" alt={staff.title} />
            <a className="u-link--primary-hover" rel="noopener noreferrer" target="_blank" href={staff.url}><h3 className="u-title">{staff.title}</h3></a>
            <h6 className="u-subtitle u-bold">{staff.subtitle}</h6>
            {
            staff.bio.map((bio, index) => {
              return (
              <p className="u-text--small" key={bio.index}>
                {bio}
              </p>
              )
            }
            )
          }
          </Cell>
        )
      }
    </Grid>
    )
}

function Advisors(props){
  const advisors = props.advisors;
  return (
    <Grid className="grid-margin-x medium-up-3">
      {
       advisors.map((advisor,index) =>
          <Cell className="c-card center">
            <img src={advisor.image} alt={advisor.title} className="c-image--150 c-image--main"/>
            <a className="u-link--primary-hover" rel="noopener noreferrer" target="_blank" href={advisor.url}><h4 className="u-bold u-text--mb0">{advisor.title}</h4></a>
            <div className="text-center u-text--light u-bold">{advisor.subtitle}</div>
          </Cell>
        )
      }
    </Grid>
  )
}

function SpecialProjects(props){
  const specialProjects = props.specialProjects;
  return (
    <Grid className="grid-margin-x medium-up-2 u-text--mb2">
      {
       specialProjects.map((specialProjects,index) =>
          <Cell className="c-card center">
            <img src={specialProjects.image} alt={specialProjects.title} className="c-image--200 c-image--main"/>
            <a className="u-link--primary-hover" rel="noopener noreferrer" target="_blank" href={specialProjects.url}><h4 className="u-bold u-text--mb0">{specialProjects.title}</h4></a>
            <div className="text-center u-text--light u-bold">{specialProjects.subtitle}</div>
          </Cell>
        )
      }
    </Grid>
  )
}


class OurCrew extends Component {
  render() {
    return (
      <div className="grid-container o-section--large">
        <h1 className="u-bold u-center">Meet our Crew</h1>
        <p className="u-description--center">Our team has been working together in different capacities for years. We share experiences in social entrepreneurship, youth movements and community engagement. As of the Fall 2024, we are all volunteering in stewarding this project until its sunset date of October 2025.</p>
        <Staff 
          staff={staff}
        />

        <h1 className="u-bold u-center">Crew Advisors</h1>
        <p className="u-description--center">We are endlessly grateful for the ongoing support of our Advisors.</p>
        <Advisors
            advisors={advisors}
          />
      </div>
    )
  }
}

export default OurCrew;
