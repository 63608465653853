import React, { Component} from 'react';
import Footer from '../footer'
import Header from '../header';
import InsertModal from '../insertModal';
import Banner from '../banner';
import BigStatement from '../bigStatement';
import CallToAction from '../callToAction';
import MemberOrgTypes from '../memberOrgTypes';
import FeaturesGrid from '../featuresGrid';
import Crew2030 from '../crew2030';
import {icons} from '../constants.js';
import {
  Grid,
  Link,
  Colors,
  Sizes
} from 'react-foundation';

import crewForAll from '../../img/logos/crewForAll.svg';


class BuildYourCrewPage extends Component {
  scrollDown() {
    window.scrollBy({
    top: 700, // negative value acceptable
    behavior: 'smooth'
  });
  }

  render() {
    return (
      <div id="crewForAll">
      <Header color="dark"/>
      <div className="sticky">
        <div className="c-image--crewForAll o-section--image o-section--large o-section--center space-between">
          <div>
            <img src={crewForAll} alt="Crew For All logo" className="c-image--200 c-icon--main c-icon--center" />
            <div className="u-background--white">
            <h2 className="o-section__headline u-bold u-title u-center">The Crew Platform is our gift to volunteer movements, accelerators and education programs working to create a world where all life can thrive.</h2>
              <p className="u-description--center u-text--so50">Crew For All is made possible by generous contributions from the Enlight Foundation and Wendy’s Wish Giving Fund, and dozens of nonprofits who contribute each year to build new features. The Crew Platform will be turning off in October 2025. From now until then, we will only continue to host the existing organizations on the platform and no new platforms will be created.</p>
            </div>
          </div>
        </div>
      </div>
      <BigStatement
        textColor='u-text--white'
        subtitle='What is Included?'
        headline='Our gift includes a new instance of the Crew Platform, ready to be customized to your community and program! Plus, access to our Tutorials and Support Platform.'
        gradient='crewForAll'
      />
      
      <div className="grid-container">
        <div className="o-section--medium o-section--center">
          <h6 className="u-subtitle">More Details</h6>
          <h3 className="u-bold">What can you expect with your customized platform ?</h3>
          <p>At Crew, all platforms features are informed and co-created by our organizations, and available to everyone.</p>
          <Grid className="grid-margin-x medium-up-3 u-text--mb2">
            <FeaturesGrid />
          </Grid>
          <Link className="u-text--mt1 u-text--mb2" href="/features" size={Sizes.Large} color={Colors.PRIMARY}>Read More About Platform Features</Link>
          </div>
      </div>
      
      <CallToAction
        headline="The Crew Platform will be turning off in October 2025"
        description="After a successful run of almost 10 years hosting groups working to make the world a more loving, just and sustainable place, the Crew for All team has decided that the best path forward in their technology evolution is to join forces with Hylo to build technology together!"
        buttonText="Read more here!"
        link="https://www.crewforall.org/news?p=hylo-and-crew"  
      />
      <Footer />

    </div>
    )
  }
}

export default BuildYourCrewPage;
